import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import "./ContactInfo.css";

const ContactInfo = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          stiffness: 100, // Adjust the stiffness
          damping: 5, // Adjust the damping (bounciness)
          duration: 1, // Adjust the duration
          delay: 0.1 // Adjust the delay
        },
      });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [controls, inView]);

  return (
    <div className="container-IN">
      <motion.div
        className="contact-item"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        ref={ref}
      >
        <div className="icon">
          <i className="fas fa-map-marker-alt"></i>
        </div>
        <div className="content">
          <p> Condor Footwear U.P. Private Limited, 7th KM, Agra Mathura Rd, Sikandra, Agra, Bainpur Mustkil, Uttar Pradesh 282007</p>
        </div>
      </motion.div>
      <motion.div
        className="contact-item"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        ref={ref}
      >
        <div className="icon">
          <i className="fa fa-phone"></i>
        </div>
        <div className="content">
          <p>+91 078303 02227</p>
        </div>
      </motion.div>
      <motion.div
        className="contact-item"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        ref={ref}
      >
        <div className="icon">
          <i className="fas fa-envelope"></i>
        </div>
        <div className="content">
          <p>internationalallied8@gmail.com</p>
        </div>
      </motion.div>
    </div>
  );
}

export default ContactInfo;
