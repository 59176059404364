import React from "react";
import "./NewArrivals.css";
import New1 from "./img/New1.jpg";
import New2 from "./img/New2.jpg";
import New3 from "./img/New3.jpg";
import New4 from "./img/New4.jpg";
import New5 from "./img/New5.jpg";
import New6 from "./img/New6.jpg";
import New7 from "./img/New7.jpg";
import New8 from "./img/New8.jpg";


const products = [
  {
    id: 1,
    title: "Product 1",
    image: New5,
  },
  {
    id: 2,
    title: "Product 2",
    image: New7,
  },
  {
    id: 3,
    title: "Product 3",
    image: New6,
  },
  {
    id: 4,
    title: "Product 4",
    image: New8,
  },
  {
    id: 5,
    title: "Product 5",
    image: New4,
  },
  {
    id: 6,
    title: "Product 6",
    image: New3,
  },
  {
    id: 7,
    title: "Product 6",
    image: New2,
  },{
    id: 8,
    title: "Product 6",
    image: New1,
  },
  // Add more products if needed
];

const NewArrivals = () => {
  return (
    <div className="new-arrivals">
      <h2 className="section-title">NEW ARRIVALS</h2>
      <div className="products-container">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            <div className="product-image">
              <img
                src={product.image}
                alt={product.title}
                className="product-thumbnail"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewArrivals;
