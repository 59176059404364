import React, { useEffect } from 'react';
import './Banner.css';
import Banner1 from "./img/Banner1.jpg";
import Banner2 from "./img/Banner2.jpg";
import Banner3 from "./img/Banner3.jpg";

const Banner = () => {
  useEffect(() => {
    // Initialize Bootstrap Carousel using JavaScript (in case it's not working)
    const carousel = new window.bootstrap.Carousel(document.getElementById('carouselExampleCaptions'));
    carousel.cycle();
  }, []);

  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="custom-carousel-button active"
            aria-current="true"
            aria-label="Slide 1"
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "100%",
              background: "white",
              border: "none",
              cursor: "pointer",
              margin: "5px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = "scale(1.3)";
              e.target.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.5)";
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
            }}
          ></button>

          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            className="custom-carousel-button"
            aria-label="Slide 2"
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "100%",
              background: "white",
              border: "none",
              cursor: "pointer",
              margin: "5px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = "scale(1.3)";
              e.target.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.5)";
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
            }}
          ></button>

          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            className="custom-carousel-button"
            aria-label="Slide 3"
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "100%",
              background: "white",
              border: "none",
              cursor: "pointer",
              margin: "5px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
            onMouseEnter={(e) => {
              e.target.style.transform = "scale(1.3)";
              e.target.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.5)";
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
            }}
          ></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Banner1} className="d-block w-100 carousel-image" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Banner2} className="d-block w-100 carousel-image" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Banner3} className="d-block w-100 carousel-image" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
