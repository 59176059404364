import React from 'react';
import Banner from './Banner';

import Collections from './Collections';
import NewArrivals from './NewArrivals';
import BestsellersShoes from './BestsellersShoes';
import WhyChooseUs from './WhyChooseUs';
import TrendingNow from './TrendingNow';

const Home = () => {
  return (
    <div>
      <br />
      <Banner />
      <br />
      <Collections />
      <br />
      <br />
      <NewArrivals /><br /><br />
      <BestsellersShoes/><br /><br />
      <TrendingNow/><br /><br />

 

  
    </div>
  );
};

export default Home;
