import React from "react";
import "./NewArrivals.css";
import New9 from "./img/New9.jpg";
import New10 from "./img/New10.jpg";
import New11 from "./img/New11.jpg";
import New12 from "./img/New12.jpg";
import New13 from "./img/New13.jpg";
import New14 from "./img/New14.jpg";
import New15 from "./img/New15.jpg";
import New16 from "./img/New16.jpg";

const products = [
  {
    id: 1,
    title: "Product 1",
    image: New13,
  },
  {
    id: 2,
    title: "Product 2",
    image: New14,
  },
  {
    id: 3,
    title: "Product 3",
    image: New15,
  },
  {
    id: 4,
    title: "Product 4",
    image: New16,
  },
  {
    id: 5,
    title: "Product 5",
    image: New9,
  },
  {
    id: 6,
    title: "Product 6",
    image: New10,
  },
  {
    id: 7,
    title: "Product 5",
    image: New11,
  },
  {
    id: 8,
    title: "Product 6",
    image: New12,
  },
  // Add more products if needed
];

const BestsellersShoes = () => {
  return (
    <div className="new-arrivals">
      <h2 className="section-title">BEST SELLER SHOES</h2>
      <div className="products-container">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            <div className="product-image">
              <img
                src={product.image}
                alt={product.title}
                className="product-thumbnail"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestsellersShoes;















































































































