import React from "react";
import "./Collections.css";
import Men1 from "./img/Men1.jpg";
import Men2 from "./img/Men2.jpg";
import Men3 from "./img/Men3.jpg";
import Men4 from "./img/Men4.jpg";
import Men5 from "./img/Men5.jpg";
import Men6 from "./img/Men6.jpg";
import Men7 from "./img/Men7.jpg";
import Men8 from "./img/Men8.jpg";






const collectionsData = [
  
  {
    title: "Men's Shoes",
    image: Men5,
    link: "/collections/mens-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men6,
    link: "/collections/womens-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men7,
    link: "/collections/kids-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men8,
    link: "/collections/new-arrivals",
  },
  {
    title: "Men's Shoes",
    image: Men1,
    link: "/collections/mens-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men2,
    link: "/collections/womens-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men3,
    link: "/collections/kids-shoes",
  },
  {
    title: "Men's Shoes",
    image: Men4,
    link: "/collections/new-arrivals",
  },
];

const Collections = () => {
  return (
    <div className="collections-container">
      <h2>Collections To Explore</h2>
      <div className="collections-grid">
        {collectionsData.map((collection, index) => (
          <a href={collection.link} key={index} className="collection-card">
            <div className="image-container">
              <img src={collection.image} alt={collection.title} />
            </div>
            <div className="collection-title">{collection.title}</div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Collections;
