import React from "react";
import "./NewArrivals.css";
// import New9 from "./img/New9.jpg";
// import New10 from "./img/New10.jpg";
// import New11 from "./img/New11.jpg";
// import New12 from "./img/New12.jpg";
import New17 from "./img/New17.jpg";
import New18 from "./img/New18.jpg";
import New19 from "./img/New19.jpg";
import New20 from "./img/New20.jpg";

const products = [
  {
    id: 1,
    title: "Product 1",
    image: New17,
  },
  {
    id: 2,
    title: "Product 2",
    image: New18,
  },
  {
    id: 3,
    title: "Product 3",
    image: New19,
  },
  {
    id: 4,
    title: "Product 4",
    image: New20,
  },
//   {
//     id: 5,
//     title: "Product 5",
//     image: New9,
//   },
//   {
//     id: 6,
//     title: "Product 6",
//     image: New10,
//   },
//   {
//     id: 7,
//     title: "Product 5",
//     image: New11,
//   },
//   {
//     id: 8,
//     title: "Product 6",
//     image: New12,
//   },
  // Add more products if needed
];

const TrendingNow = () => {
  return (
    <div className="new-arrivals">
      <h2 className="section-title">TRENDING NOW</h2>
      <div className="products-container">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            <div className="product-image">
              <img
                src={product.image}
                alt={product.title}
                className="product-thumbnail"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingNow;






