// src/Footer.js
import React from 'react';
import './Footer.css';
import Salogo2 from "../components/img/Salogo2.png";
import '@fortawesome/fontawesome-free/css/all.min.css';
// import tripadvisor from "./img/tripadvisor.jpg";

const Footer = () => {
  return (
    
    <footer className="footer py-5">
      
      <div className="footer-section contact-us  ">
        <img src={Salogo2} alt="Logo" className="footer-logo" /><br /><br />
        <h5><b>Walk the World in Style</b></h5><p className="text-footer-p">International Allied Discover shoes that blend fashion and functionality perfectly. From timeless classics to modern designs, our collection ensures every step you take is confident and comfortable. Explore a range of styles crafted with precision and care, designed to elevate your look for any occasion.</p>
        {/* <p>Phone: <a href="tel:+918377855100">+91-8377855100</a></p>
        <p>Email: <a href="mailto:info@zeeva.in">info@zeeva.in</a></p> */}
      </div>
      <div className="footer-section quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/">Formal</a></li>
          <li><a href="/">Casual</a></li>
          <li><a href="/">Loafers</a></li>
          <li><a href="/">Slippers</a></li>
          <li><a href="/contact-us">Contact Us</a></li>
        </ul>
        
        
      </div>
      
      
      {/* <div className="footer-section opening-hours"> */}
        
      {/* <div className="footer-sectionp quick-links">
        <h3>Tour Packages</h3>
        <ul>
          <li><a href="/full-day-new-and-old-delhi-city-tour">Delhi City Tour Full Day</a></li>
          <li><a href="/taj-mahal-tour-from-delhi-by-car">Taj Mahal Tour from Delhi by Car</a></li>
          <li><a href="/sunrise-taj-mahal-tour-from-delhi">Sunrise Taj Mahal Tour from Delhi by Car          </a></li>
          <li><a href="/6-Day-golden-triangle-tour-with-varanasi">6 Day Golden Triangle Tour with Varanasi</a></li> */}
          {/* <li><a href="#mauritius">Blogs</a></li> */}
          {/* <li><a href="/contact-us">Contact Us</a></li> */}
        {/* </ul>
        
        
      </div> */}
        {/* <div className="social-icons">
          <a href="https://www.facebook.com/" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>

          <a href="https://www.instagram.com/" aria-label="Instagram" target="_blank" rel="noopener noreferrer" ><i className="fab fa-instagram ms-2"></i></a> */}

          {/* <a href="https://www.twitter.com/" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a> */}
          {/* <a href="https://www.linkdin.com/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a> */}
          {/* <a href="https://www.youtube.com/" aria-label="YouTube" target="_blank" rel="noopener noreferrer" ><i className="fab fa-youtube ms-2"></i></a> */}
          {/* <a href="https://www.google.com/search?kgmid=/g/11vp_f5w43&hl=en-IN&q=Dr.+Vaishali+Tandon&kgs=0b01d5c6f42a5fb0&shndl=30&shem=lrnole,ssic&source=sh/x/loc/osrp/m5/4#lrd=0x3974779af6cb7cef:0xe02e9fe2e36d2145,1,,,," aria-label="Google" target="_blank" rel="noopener noreferrer" ><i className="fab fa-google ms-2"></i></a> */}

        {/* </div> */}
      {/* </div> */}
      
      <div className="footer-section opening-hours">
        <h3>Find Us On</h3>
        <a rel="nofollow" className="component-target" href="tel:+(91)-7830302227">
          <i className="fa fa-phone"></i>
          <span className="component-target-text"> +(91)-78303 02227</span>
          
       </a><br />
        <a  href="mailto:internationalallied8@gmail.com"><br /><i className="fas fa-envelope"></i> internationalallied8@gmail.com</a>
        <div className="footer-section location text-start ms-0"><br />
        <h3>Location</h3> 
        <p>Visit us at our location:</p>
        <span><p><span><i className="fas fa-map-marker-alt " ></i> Condor Footwear U.P. Private Limited, 7th KM, Agra Mathura Rd, Sikandra, Agra, Bainpur Mustkil, Uttar Pradesh 282007</span></p></span>
      </div>
     
      

        
      {/* <a href="https://www.tripadvisor.in/Attraction_Review-g297683-d19593752-Reviews-Babloo_Heritage_Tuk_Tuk-Agra_Agra_District_Uttar_Pradesh.html" aria-label="Tripadvisor" target="_blank" rel="noopener noreferrer"><img className='trip-img' src={tripadvisor} alt="" /></a> */}
      </div>
      
      <div className="copyright ">
      <p className='text-whitesmoke'>©International Allied 2024 | All rights reserved. Design and Developed by <span><a href="https://www.magnumdigitalsolution.com/" target="_blank" rel="noreferrer noreferrer">Magnum Digital Solutions </a></span></p>
      </div>
     
      
      
      
    </footer>
    
    
  );
};

export default Footer;
