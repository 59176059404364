import React, { useState } from 'react';
import Salogo from "../components/img/Salogo.png";
import './Navbar.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './Home';
import ContactUs from './ContactUs';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component



const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemClick = () => setIsNavCollapsed(true);

  return (
    <Router>
      <div>
        <ScrollToTop /> {/* Add the ScrollToTop component here */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <div className="container" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <span role="img" aria-label="Hello">
                  <img src={Salogo} alt="" />
                </span>
              </Link>
              <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                <ul className="navbar-nav  ">
                  <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>HOME</Link>
                  </li>
                  
                   <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>FORMAL</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>CASUAL</Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>LOAFERS</Link>
                  </li>
                  
                 
                     <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>SLIPPERS</Link>
                  </li>
                 
                   
                
               
                
                  <li className="nav-item">
                    <Link className="nav-link women-link " to="/contact-us" onClick={handleNavItemClick} style={{color: 'black'}}>CONTACT US</Link>
                  </li>
                  
                </ul>
               
              </div>
             
            </div>
           
          </nav>
        </header>
        <div className="main-content"> {/* Added this wrapper div */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
          
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Navbar;
